import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState('')


  const handleLogin = async e => {
    e.preventDefault()
    setError('')

    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)

    await axios.post('/users/login', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        // console.log('res.data', res.data)
        localStorage.setItem('user', res.data)

        if (res.data.success) {
          navigate('/file/upload')
        } else {
          setError(res.data.message)
        }
      })
      .catch(err => { 
        console.log('err:', err)
        setError(err.response.data.message)
      }) 
  }


  return (
    <div>

      <div class="flex justify-center items-center pt-20">
        <div class="w-96 p-6 shadow-lg bg-white rounded-md">
          <h1 class="text-3xl block text-center font-semibold">Login</h1>
          
          { error &&
            <div class="text-center mt-7 p-4 mb-4 text-sm text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
              { error }
            </div>
          }

          <form onSubmit={handleLogin}>
            <div class="mt-6">
              <input 
                type="email" 
                id="email" 
                class="border w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-blue-600" 
                placeholder="Enter email"
                onChange={e => setEmail(e.target.value)} />
            </div>

            <div class="mt-6">
              <input 
                type="password" 
                id="password" 
                class="border w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-blue-600" 
                placeholder="Enter password"
                onChange={e => setPassword(e.target.value)} />
            </div>

            <div class="mt-5">
              <button type="submit" class="border-2 border-blue-700 bg-blue-700 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-blue-700">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
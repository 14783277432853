import React from 'react'
import { useNavigate } from 'react-router-dom'



function Navbar(active) {

  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.clear()
    navigate('/')
  }
  
  return (
    <>
    <div class="flex md:flex md:flex-grow flex-row justify-end space-x-1 pb-4">
      {/* <a href="" class="py-4 px-2 text-teal-500 border-b-4 border-teal-300 font-semibold">Home</a>
      <a href="" class="py-4 px-2 text-gray-500 font-semibold hover:text-teal-300 transition duration-300">Services</a> */}
      <a href="" 
        onClick={handleLogout}
        class="py-4 px-2 text-gray-500 font-semibold hover:text-blue-700 transition duration-300">Logout</a>
    </div>
    </>
  )
}

export default Navbar
import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import FileUpload from './pages/FileUpload'


const router = createBrowserRouter([
  { path: '/', element: <Login /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/file/upload', element: <FileUpload /> },
])

function App() {
  // if(!localStorage.getItem("user")) {
  //   return <Login />
  // }

  return (
    <div className="container mt-4">
      <h4 className="logo display-6 text-left mb-4 pl-9 pt-4">
        Level 9 GEO
      </h4>
      <RouterProvider router={router} />
    </div>
  )
}

export default App;

import React, { Fragment, useState } from 'react'
import Navigation from '../components/Navigation'
import axios from 'axios'


const FileUpload = () => {

  const [geoFile, setGeoFile] = useState('')
  const [category, setCategory] = useState('')
  const [filename, setFilename] = useState('Choose File')
  const [uploadedFile, setUploadedFile] = useState({})
  const [htmlInput, setHtmlInput] = useState('')
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState('')
  const [htmlOutput, setHtmlOutput] = useState('')

  const onFileChange = async e => {
    setGeoFile(e.target.files[0])
    setFilename(e.target.files[0].name)
  }
  const onCategoryChange = async e => {
    setCategory(e.target.value)
  }

  const onFileSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('geoFile', geoFile)
    formData.append('category', category)

    try {
      const res = await axios.post('/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      const {fileName, filePath } = res.data // get the file name and path from the response
      setUploadedFile({fileName, filePath}) // set the state to the uploaded file

      setMessage(res.data.msg)
      setIsSuccess(res.data.success)


      // save file contents to state
      let reader = new FileReader()
      reader.onload = async (e) => { 
        const text = (e.target.result)
        setHtmlInput(text)
      }

    } catch (err) {
      setMessage(err.response.data.msg)
      setIsSuccess(err.response.data.success)
    }
  }


  return (
    <Fragment>
      <Navigation />

      <div class="pl-8 pr-8">

        { message && isSuccess === true &&
          <div class="text-center mt-7 p-4 mb-4 text-sm text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
            { message }
          </div>
        }
        { message && isSuccess === false &&
          <div class="text-center mt-7 p-4 mb-4 text-sm text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            { message }
          </div>
        }

        <form onSubmit={onFileSubmit} acceptCharset="UTF-8">
          <div class="grid grid-cols-[30%_70%] w-full h-64">
            <div class="pr-4">
              <h3>Upload Options</h3>
              <select 
                id="category" 
                name="category" 
                class="border w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-blue-600" 
                onChange={onCategoryChange}>
                  <option value="" disabled selected>- Geo File Category -</option>
                  <option value="consulates">Consulate</option>
                  <option value="embassies">Embassy</option>
                  <option value="military_bases">Military Base</option>
              </select>
            </div>
            <div>
              <label for="file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">Upload GEO JSON File</p>
                  </div>
                  {/* <input id="file" name="file" type="file" class="hidden" onChange={onFileChange} /> */}
                  <input id="geoFile" name="geoFile" type="file" onChange={onFileChange} />
              </label>
            </div>
          </div>

          <input 
            type="submit" 
            value="Upload" 
            class="border-2 mt-10 border-blue-700 bg-blue-700 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-blue-700" />
        </form>


        { uploadedFile ? <div className="row mt-5">
          {htmlOutput.length > 0 && 
            <div>
              
              <div className="row">
                <div className="col-sm-6">
                  <h3>Preview</h3> 
                </div>  
                <div className="col-sm-6 text-end">
                  <a href="#" style={{ textDecoration: 'none'}}
                    onClick={() => {navigator.clipboard.writeText(htmlOutput)}}>
                    <i className="fa fa-thin fa-copy"></i> Copy HTML
                  </a>
                </div>
              </div>
              <hr style={{float: 'none'}}/>
              <iframe title="Report" style={{ width: '100%', height: '720px' }} srcDoc={htmlOutput}></iframe>
            </div>
          }
        </div> : null }

      </div>
    </Fragment>
  )
}

export default FileUpload
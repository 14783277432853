import React from 'react'
import Navigation from '../components/Navigation'
import axios from 'axios'


const Dashboard = () => {
  return (
    <div>
      <Navigation />
      

      <div class="flex justify-center items-center pt-5">
        <div class="w-3/4 p-6">
          <div class="flex flex-row">
            <div class="basis-full">
              [file upload here]
            </div>
          </div>
        </div>
      </div>


    </div>
    
  )
}

export default Dashboard